import { App } from 'src/components/App'
import CommunityProfile from 'src/views/Profile/CommunityProfile'
import { MemberProvider } from 'src/providers/MemberProvider'
import { Overview } from 'src/views/Profile/Overview'
import { SplitUserTreatmentsProvider } from 'src/providers/Split/UserTreatmentsProvider'

const SocialProfile = () => (
  <App.Layout>
    <App.RequireUser.IsMember>
      <MemberProvider>
        {({ isCommunity }) =>
          isCommunity ? (
            <CommunityProfile />
          ) : (
            <SplitUserTreatmentsProvider context='userProfile'>
              <App.ProfileLayout>
                <Overview />
              </App.ProfileLayout>
            </SplitUserTreatmentsProvider>
          )
        }
      </MemberProvider>
    </App.RequireUser.IsMember>
  </App.Layout>
)

export default SocialProfile
